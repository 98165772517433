<template>
  <div>
    <chart :cdata="cdata"></chart>
  </div>
</template>

<script>
  import Chart from "./chart.vue";
  export default {
    components: {
      Chart,
    },
    props:{
      url:String,
    },
    data() {
      return {
        cdata: {
          category: [
            "市区",
            "万州",
            "江北",
            "南岸",
            "北碚",
            "綦南",
            "长寿",
            "永川",
            "璧山",
            "江津",
            "城口",
            "大足",
            "垫江",
            "丰都",
            "奉节",
            "合川",
            "江津区",
            "开州",
            "南川",
            "彭水",
            "黔江",
            "石柱",
            "铜梁",
            "潼南",
            "巫山",
            "巫溪",
            "武隆",
            "秀山",
            "酉阳",
            "云阳",
            "忠县",
            "川东",
            "检修",
          ],
          lineData: [
            18092,
            20728,
            24045,
            28348,
            32808,
            36097,
            39867,
            44715,
            48444,
            50415,
            56061,
            62677,
            59521,
            67560,
            18092,
            20728,
            24045,
            28348,
            32808,
            36097,
            39867,
            44715,
            48444,
            50415,
            36097,
            39867,
            44715,
            48444,
            50415,
            50061,
            32677,
            49521,
            32808,
          ],
          barData: [
            4600,
            5000,
            5500,
            6500,
            7500,
            8500,
            9900,
            12500,
            14000,
            21500,
            23200,
            24450,
            25250,
            33300,
            4600,
            5000,
            5500,
            6500,
            7500,
            8500,
            9900,
            22500,
            14000,
            21500,
            8500,
            9900,
            12500,
            14000,
            21500,
            23200,
            24450,
            25250,
            7500,
          ],
          rateData: [],
        },
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      // 根据自己的业务情况修改
      getData() {
        let params = this.params;
        let http = this.post ? this.$http.post(this.url, params) : this.$http.get(this.url, params);
        http.then(data => {

          this.cdata = data.list.cdata;
          for (let i = 0; i < this.cdata.barData.length -1; i++) {
            let rate = this.cdata.barData[i] / this.cdata.lineData[i];
            this.cdata.rateData.push(rate.toFixed(2));
          }
          // this.setData();
          // this.status = "finish";
        }).catch(() => {
          // this.status = "error";
        });
      },
      setData() {
        for (let i = 0; i < this.cdata.barData.length -1; i++) {
          let rate = this.cdata.barData[i] / this.cdata.lineData[i];
          this.cdata.rateData.push(rate.toFixed(2));
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>