<template>
  <div id="centerRight2">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="align-left" class="text-icon"></icon>
        </span>
        <span class="fs-xl text mx-2">产品销售渠道分析</span>
      </div>
      <div class="d-flex ai-center flex-column body-box">
        <dv-capsule-chart class="dv-cap-chart" :config="config"></dv-capsule-chart>
        <centerRight2Chart1></centerRight2Chart1>
      </div>
    </div>
  </div>
</template>

<script>
  import centerRight2Chart1 from "@/components/echart/centerRight/centerRightChart";

  export default {
    components: { centerRight2Chart1 },
    props:{
      url:String,
    },
    data() {
      return {
        config:null,
      };
    },
    mounted() {
      this.getData();
    },
    methods:{
      getData() {
        let params = this.params;
        let http = this.post ? this.$http.post(this.url, params) : this.$http.get(this.url, params);
        http.then(data => {
          this.config = {data:data.list};
          // this.status = "finish";
        }).catch(() => {
          // this.status = "error";
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
#centerRight2 {
  $box-height: 410px;
  $box-width: 340px;
  padding: 5px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    padding: 5px;
    height: $box-height;
    width: $box-width;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-cap-chart {
      width: 100%;
      height: 160px;
    }
  }
}
</style>
