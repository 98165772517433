<template>
  <div id="bg">
    <search-pic :type="type">
      <!-- 第三行数据 -->

      <div class="host-body">
        <div class="content-box">
          <div>
            <dv-border-box-12 style="padding: 10px ">
              <allinone
                :id="type.defaultLine"
                :type="type.defaultLine"
                height="350px"
                width="300px"
                url="bigscreen/defaultline"
                name="各品牌一周内维修次数"

              ></allinone>
            </dv-border-box-12>
          </div>
          <div>
            <dv-border-box-12>
              <centerLeft2></centerLeft2>
            </dv-border-box-12>
          </div>
          <!-- 中间 -->
          <div>
            <center></center>
          </div>
          <!-- 中间 -->
          <div>
            <centerRight2 url="bigscreen/capsule"></centerRight2>
          </div>
          <div>
            <dv-border-box-13>
              <centerRight1></centerRight1>
            </dv-border-box-13>
          </div>
        </div>

        <!-- 第四行数据 -->
        <div class="bototm-box">
          <dv-border-box-13>
            <bottomLeft></bottomLeft>
          </dv-border-box-13>
          <dv-border-box-12>
            <bottomRight></bottomRight>
          </dv-border-box-12>
        </div>
      </div>
    </search-pic>
  </div>
</template>
<script>
  import { formatTime } from "@/util";
  import centerLeft2 from "@/views/bigScreen/centerLeft2";
  import centerRight1 from "@/views/bigScreen/centerRight1";
  import centerRight2 from "@/views/bigScreen/centerRight2";
  import center from "@/views/bigScreen/center";
  import bottomLeft from "@/views/bigScreen/bottomLeft";
  import bottomRight from "@/views/bigScreen/bottomRight";
  import SearchPic from "@/views/template/SearchPic";
  import allinone from "@/common/echart/allinone";
  export default {
    components: {
      centerLeft2,
      centerRight1,
      centerRight2,
      center,
      bottomLeft,
      bottomRight,
      SearchPic,
      allinone,
    },
    data() {
      return{
        filter: {
          name: "",
          regCode: "",
          factoryCode: "",
          rescueCode: "",
          type: "",
          status: "",
          projectName: "",
          deviceNo: "",
        },
        type: {
          axisBar: "axisBar",//折线柱状图
          defaultLine: "defaultLine",//默认的折线图
          radarchart: "RadarChart",//雷达图
          sankey: "sankey",//桑基图
          scatter: "scatter",//气泡图
          stackCharts: "stackCharts",
          sunburst: "sunburst",
          wordcloud: "wordcloud",
          ybar: "ybar",
          pie:"pie",
          bar:"bar",
        },
        option:{
          indicator:{},
        },
      };
    },
    mounted() {
      this.cancelLoading();
    },
    beforeDestroy() {
      clearInterval(this.timing);
    },
    methods: {
      timeFn() {
        this.timing = setInterval(() => {
          this.dateDay = formatTime(new Date(), "HH: mm: ss");
          this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
          this.dateWeek = this.weekday[new Date().getDay()];
        }, 1000);
      },
      cancelLoading() {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      },
      goback() {
        this.$router.go(-1);
      },
    },
  };
</script>

<style lang="scss">
#bg{

  width: 100%;
  height: 100%;
  padding: 16px 16px 0 16px;
  background-image: url("../../assets/pageBg.png");
  background-size: cover;
  background-position: center center;

}
.content-box{
  display: flex;
}
.bototm-box{
  display: flex;
}
.host-body{
  height: 80%;
  width: 100%;
  overflow: hidden;
}
</style>
